// import { Link } from 'gatsby';
import React from 'react';
// import Link from '@/components/Link';

export const FAQQuestionsAnswers = [
  {
    title: `Account`,
    // & Memberships`,
    qa: [
      // {
      //   question: `How do I know what membership plan to choose?`,
      //   answer: {
      //     text: `We offer a one-time gut test and a membership plan (which includes 2 gut tests shipped to you every 6 months, plus a monthly nutrition coaching). Single gut tests come with a free 15-minute consultation with our licensed practitioners who will help you understand your result better. Our membership on the other hand, allows us to partner with you through your wellness journey. See membership to learn more.`,
      //   },
      // },
      // {
      //   question: `What does my membership entail?`,
      //   answer: {
      //     component: (
      //       <h3 className="md:text-[18px] text-[#36405C] opacity-90">
      //         Details about what each membership is comprised of can be found
      //         {` `}
      //         <Link
      //           href="/pricing"
      //           className="border-b text-primary border-primary"
      //         >
      //           here
      //         </Link>
      //       </h3>
      //     ),
      //   },
      // },
      // {
      //   question: `How can I cancel my membership?`,
      //   answer: {
      //     component: (
      //       <h3 className="md:text-[18px] text-[#36405C] opacity-90">
      //         You can cancel your membership at least 7 days before your next
      //         billing cycle. If you wish to ask any questions or cancel your
      //         membership, please reach out to us at{` `}
      //         <a
      //           href="mailto:hello@vitract.com"
      //           className="border-b text-primary border-primary"
      //         >
      //           hello@vitract.com
      //         </a>
      //       </h3>
      //     ),
      //   },
      // },
      // {
      //   question: `Does my membership renew automatically?`,
      //   answer: {
      //     text: `Yes, your membership renews automatically. Membership date starts
      //         to count from the day you subscribe.`,
      //   },
      // },
      {
        question: `Can I set up multiple accounts?`,
        answer: {
          text: `Everyone can set up one Vitract account each with a unique email address.`,
        },
      },
      {
        question: `Do I need to set up multiple accounts every time I do a gut test?`,
        answer: {
          text: `No. We can add all your gut test results in a single account profile. This allows you monitor your progress overtime.`,
        },
      },
    ],
  },
  {
    title: `Billing`,
    qa: [
      // {
      //   question: `What does my membership entail?`,
      //   answer: {
      //     component: (
      //       <h3 className="md:text-[18px] text-[#36405C] opacity-90">
      //         At this time, you can pay for your test only on the Vitract
      //         website. Use this
      //         {` `}
      //         <Link
      //           href="/pricing"
      //           className="border-b text-primary border-primary"
      //         >
      //           link
      //         </Link>
      //         {` `}
      //         and choose the plan you want to pay for.
      //       </h3>
      //     ),
      //   },
      // },
      {
        question: `What is Vitract’s refund policy?`,
        answer: {
          text: `We refund on a case-to-case basis. Typically, customers who prove that they did not receive their test kits can either be refunded or the kit replaced. Refund may also be processed before your test kit ships. Initial payment processing fee may be deducted in this case though.`,
        },
      },
    ],
  },
  {
    title: `Contacting Vitract`,
    qa: [
      {
        question: `How can I contact Vitract support?`,
        answer: {
          component: (
            <h3 className="md:text-[18px] text-[#36405C] opacity-90">
              You can reach out to Vitract on
              {` `}
              <a
                href="mailto:hello@vitract.com"
                className="border-b text-primary border-primary"
              >
                hello@vitract.com
              </a>
              {` `}
            </h3>
          ),
        },
      },
      {
        question: `What is the average response time for Vitract to get back to me?`,
        answer: {
          text: `We will typically get back to you within 24 hours.`,
        },
      },
    ],
  },
  {
    title: `Gut Test`,
    qa: [
      {
        question: `What is the Vitract Gut Microbiome test?`,
        answer: {
          text: `The Vitract gut test uses 16s rRNA (ribosomal RNA sequencing) to identify the various bacterial groups that are present in an individual’s gut ecosystem. The goal of the test is to screen for biomarkers of brain and gut health.`,
        },
      },
      {
        question: `How useful is this test for someone with anxiety and/or depression?`,
        answer: {
          text: `The Vitract gut test helps practitioners make insightful decisions on how to help their patients with nutritional changes to improve their mental health outcomes. This test reports the distribution of bacterial groups, their diversity, which altogether are linked to chemical, immune and neural signalling pathways which communicate to the brain through the gut-brain connection. Our technology is fed with data from peer-reviewed studies that show empirical linkage of the gut bacteria, nutrition, and mental health outcomes.`,
        },
      },
      {
        question: `Is the Vitract Gut Test a diagnostic test?`,
        answer: {
          text: `Gut microbiome tests are generally not used as diagnostic tests by traditional doctors. However, it is a functional test that provides an array of valuable information that practitioners can learn from to treat their patients.`,
        },
      },
      {
        question: `How long do the kits last?`,
        answer: {
          text: `Our preservation buffer (which preserves the bacterial DNA) has a shelf-life of 3 years from production date. We will ensure we do not send an out-of-date kit but please check the expiry date on the outer packaging or collection tube that contains the preservation buffer, before opening your kit.`,
        },
      },
      {
        question: `How long can the bacterial DNA in the kit be preserved for?`,
        answer: {
          text: `The preservation buffer can preserve bacterial DNA in ambient temperatures for up to 3 years, however this is subject to the expiry date on your kit, therefore please check your expiry date before collecting your sample.
          You can take the gut test as many times as you want, although we advise that you take it 3 times within a year.`,
          //           – First, before you commence your nutrition program with us. Secondly, 4 months into your nutrition coaching, and subsequently, 9 months after your first test. Multiple tests across several months allow you to monitor habits potentially influencing your gut health.
          // `,
        },
      },
      {
        question: `Can I get a gut test shipped to me anywhere in the United States?`,
        answer: {
          text: `We ship to every state in the United States.`,
        },
      },
      {
        question: `Can I get a gut test shipped to me internationally?`,
        answer: {
          text: `As of now, we only ship to states within the US. Starting January 2023, we will commence shipment to Canada.`,
        },
      },
      {
        question: `Is the gut test covered by Insurance?`,
        answer: {
          text: `At this time, our gut test is not covered by insurance.`,
          // However, our nutrition program is covered by Aetna insurance if you are a resident of New York.`,
        },
      },
      {
        question: `How long does it take for me to receive the gut test after I place an order?`,
        answer: {
          text: `It will take 2 to 5 business days.`,
        },
      },
      {
        question: `How long does it take to receive my gut test result?`,
        answer: {
          text: `Your results will be ready 2 weeks after the receipt of your sample. You will receive an email notification when your results are in.`,
        },
      },
      {
        question: `What is contained in the test kit?`,
        answer: {
          text: `Collection tube with DNA preservation buffer, swab, return mailer with plastic bag, absorbent sheet and collection Instructions.`,
        },
      },
    ],
  },
  // {
  //   title: `Nutrition Program`,
  //   qa: [
  //     {
  //       question: `Must I do the nutrition program alongside the gut test?`,
  //       answer: {
  //         text: `No, although we strongly encourage it.`,
  //       },
  //     },
  //     {
  //       question: `How long does the nutrition program last?`,
  //       answer: {
  //         text: `Typically, for one year after which we continue to support you for a lifetime.`,
  //       },
  //     },
  //     {
  //       question: `Do I pay separately for the nutrition program?`,
  //       answer: {
  //         text: `Yes, you can pay for the nutrition coaching separately. Also, your membership comes with a monthly nutrition coaching.`,
  //       },
  //     },
  //     {
  //       question: `Is the nutrition program covered by Insurance?`,
  //       answer: {
  //         text: `Yes. We are covered by Aetna if you are a resident of New York.`,
  //       },
  //     },
  //   ],
  // },
];
