import { ArrowDown, ArrowRight } from '@/components/icons/Arrows';
import React from 'react';
import { FAQQuestionsAnswers } from './data';

export default function FAQ() {
  const [faqOpen, setFAQOpen] = React.useState<string[]>([]);
  const toggleFAQSelection = (key: string, isOpen: boolean) => {
    if (isOpen) {
      setFAQOpen((faqs) => faqs.filter((faqKey: string) => key !== faqKey));
    } else setFAQOpen([...faqOpen, key]);
  };

  return (
    <section id="mem" className="bg-white relative pb-[64px] lg:pb-[120px]">
      <div className="bg-[#CFEDD3] py-16 md:pt-[132px] md:pb-[161px] relative md:h-[413px]">
        <div className="px-8 mx-auto text-center md:px-0">
          <legend className="font-bold text-center mx-auto md:text-[60px] text-2xl text-[#484352] md:leading-[60px]">
            Frequently Asked Questions (FAQs) <br />
            <span className="text-[##36405C] font-[500] md:text-[18px] text-[14px]">
              Have questions to ask? We are here to help you.
            </span>
          </legend>
        </div>
        <img
          src="/images/faq-2.png"
          alt="Vitract - Pricing"
          className="absolute left-0 top-[-50px] hidden sm:block"
        />
        <img
          src="/images/faq-1.png"
          alt="Vitract - Pricing"
          className="absolute right-0 top-[63px] hidden sm:block"
        />
      </div>

      <div className="mt-[32px] lg:mt-[82px] xl:max-w-[1475px] md:max-w-[81%] lg:mx-[130px] mx-auto flex flex-col gap-y-[20px] md:gap-x-[20px] lg:gap-y-0 px-4 md:px-0">
        {FAQQuestionsAnswers.map((faq, i) => (
          <div
            key={faq.title}
            className={`flex lg:flex-row flex-col gap-[40px] lg:gap-[120px] w-full justify-between ${
              i === 1 ? `mt-[40px] lg:mt-[130px]` : ``
            } ${i > 1 ? `mt-[40px] lg:mt-[130px]` : ``}`}
          >
            <h2 className="sm:text-[32px] text-[24px] font-bold text-primary">
              {faq.title}
            </h2>
            <div className="grid gap-y-[24px] sm:gap-y-[32px] max-w-[720px] w-full">
              {faq.qa.map((qa, qai) => {
                const key = `${i}-${qai}`;
                const isOpen = faqOpen.includes(key);
                return (
                  <div
                    key={key}
                    className="flex flex-col border-b max-w-[707px] pb-[32px]"
                  >
                    <button
                      type="button"
                      onClick={() => toggleFAQSelection(key, isOpen)}
                      className="text-[#484352] sm:text-[24px] text-[18px] font-[500] flex items-start justify-between pr-[10px] cursor-pointer text-left"
                    >
                      {qa.question}

                      <span className="opacity-80">
                        {isOpen ? <ArrowDown /> : <ArrowRight />}
                      </span>
                    </button>
                    <div className={`mt-[32px] ${isOpen ? `block` : `hidden`}`}>
                      {qa.answer.text && (
                        <h4 className="md:text-[18px] text-[#36405C] opacity-90">
                          {qa.answer.text}
                        </h4>
                      )}
                      {qa.answer.component && qa.answer.component}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
